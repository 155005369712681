:root {
  --primary-color-text: rgba(255, 255, 255, 0.842);
}

body {
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(31, 39, 50);
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
  
  font-size: 16px;
  background-color: rgba(31, 39, 50, 0.81);
  color: var(--primary-color-text);
  
}

